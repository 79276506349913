.in {
  flex: 1 1 auto;
  min-width: 0;
  margin-bottom: 12px;

  .inTitle {
    display: block;
    color: var(--color-text-70);
    font-size: 0.8rem;
    user-select: none;
    margin-bottom: 6px;
    text-align: left;
    white-space: nowrap;
  }
  .inContent {
    position: relative;
    display: flex;
    border-radius: 8px;
    background-color: #f9fafb;
    cursor: pointer;
    min-height: 44px;
    border: 1px solid var(--color-neutral-100);
    box-sizing: border-box;
    font-size: 0.9rem;
    transition: all 200ms;

    .inPrefix,
    .inSuffix {
      display: flex;
      align-items: center;
      justify-content: center;
      color: var(--color-text-40);
      font-weight: 300;
      pointer-events: none;
      line-height: 1;
    }
    .inPrefix {
      padding-left: 12px;
    }
    .inSuffix {
      padding-right: 12px;
    }

    .inDivider {
      width: 1px;
      background-color: var(--color-neutral-100);
      flex-shrink: 0;
      margin: 8px 0;
      transition: all 200ms;
    }
    input {
      border: none;
      background: none;
      outline: none;
      padding: 12px 14px;
      box-sizing: border-box;
      text-overflow: ellipsis;
      flex: 1;
      width: 100%;
      font-weight: 500;
      line-height: 1;
      appearance: none;

      &::placeholder {
        font-weight: 300;
        color: var(--color-text-40);
      }
    }
  }

  &.full {
    border-radius: 8px;
    background-color: #f9fafb;
    border: 1px solid var(--color-neutral-100);
    padding: 12px 14px;
    transition: all 200ms;

    .inContent {
      background: none;
      border-radius: 0;
      border: none;
      min-height: auto;

      input {
        padding: 0;
      }
    }

    &:focus-within {
      border-color: var(--color-accent-100);
      background-color: rgba(41, 105, 224, 0.1);
    }
  }
  &.error {
    border-color: #fb5f5f;

    .inTitle {
      color: #fb5f5f;
    }
    .inContent {
      border-color: #fb5f5f;
    }
  }
  &.disabled {
    .inContent {
      cursor: not-allowed;
      background-color: var(--color-neutral-100);

      input {
        cursor: not-allowed;
      }
    }
  }

  &:not(.full):focus-within {
    .inContent {
      border-color: var(--color-accent-100);
      background-color: rgba(41, 105, 224, 0.1);

      .inDivider {
        background-color: var(--color-accent-100);
      }
    }
  }
}
