.cb {
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  min-width: 0;
  margin-bottom: 12px;

  &.inline {
    flex-direction: row;
  }

  .cbTitle {
    display: block;
    color: var(--color-text-70);
    font-size: 0.8rem;
    user-select: none;
    margin-bottom: 6px;
    text-align: left;
    white-space: nowrap;
  }
  .cbContent {
    display: flex;
    flex-wrap: wrap;
    margin: -4px 0 0 -4px;

    .cbChekbox {
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      padding: 12px 14px;
      box-sizing: border-box;
      min-height: 44px;
      border-radius: 8px;
      font-weight: 500;
      line-height: 1;
      background-color: #f9fafb;
      cursor: pointer;
      outline: none;
      color: var(--color-text-100);
      border: 1px solid var(--color-neutral-100);
      transition: all 200ms;
      white-space: nowrap;
      user-select: none;
      margin: 4px 0 0 4px;
      font-size: 0.9rem;

      &:not(.disabled):hover {
        background-color: rgba(41, 105, 224, 0.1);
        border-color: var(--color-accent-100);
        color: var(--color-accent-100);
      }
      &:not(.disabled):focus {
        background-color: rgba(41, 105, 224, 0.15);
        border-color: var(--color-accent-100);
        color: var(--color-accent-100);
      }
      &:not(.disabled):active {
        background-color: rgba(41, 105, 224, 0.2);
        transform: scale(0.95);
      }

      &.active {
        background-color: var(--color-accent-100) !important;
        border-color: var(--color-accent-100) !important;
        color: white !important;
      }
      &.disabled {
        color: var(--color-text-40);
        cursor: not-allowed;
        background: none;
        border-color: #f7fbff;
      }
    }
  }
}
