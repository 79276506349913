.txt {
  flex: 1 1 auto;
  min-width: 0;
  margin-bottom: 12px;
  cursor: pointer;

  .txtTitle {
    display: block;
    color: var(--color-text-70);
    font-size: 0.8rem;
    user-select: none;
    margin-bottom: 6px;
    text-align: left;
    white-space: nowrap;
  }
  .txtContent {
    position: relative;
    display: flex;
    border-radius: 8px;
    background-color: #f9fafb;
    cursor: pointer;
    min-height: 44px;
    border: 1px solid var(--color-neutral-100);
    font-size: 0.9rem;
    transition:
      border-color 200ms,
      background-color 200ms;

    textarea {
      width: 100%;
      max-width: 100%;
      min-width: 100%;
      min-height: 100px;
      box-sizing: border-box;
      background: none;
      border: none;
      outline: none;
      padding: 16px 18px;
      overflow: hidden;

      &::placeholder {
        font-weight: 300;
        color: var(--color-text-40);
      }
    }
  }

  &.full {
    border-radius: 8px;
    background-color: #f9fafb;
    border: 1px solid var(--color-neutral-100);
    padding: 12px 14px;
    transition: all 200ms;

    .txtContent {
      background: none;
      border-radius: 0;
      border: none;
      min-height: auto;

      textarea {
        padding: 0;
        resize: none;
      }
    }

    &:focus-within {
      border-color: var(--color-accent-100);
      background-color: rgba(41, 105, 224, 0.1);
    }
  }
  &.error {
    border-color: #fb5f5f;

    .txtTitle {
      color: #fb5f5f;
    }
    .txtContent {
      border-color: #fb5f5f;
    }
  }

  &:not(.full):focus-within {
    .txtContent {
      border-color: var(--color-accent-100);
      background-color: rgba(41, 105, 224, 0.1);
    }
  }
}
